import { ingenium_logo, ingenium_logo_small, ingenium_off } from "./ingenium";

import { solidSet, linearSet, duotoneSet /*flagSet, brandSet */ } from "@coreui/icons-pro";

export const icons = Object.assign(
  {
    ingenium_logo,
    ingenium_logo_small,
    ingenium_off,
  },
  solidSet,
  linearSet,
  duotoneSet
  /* flagSet,
  brandSet*/
);
