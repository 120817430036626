import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { registerSW } from 'virtual:pwa-register'

import { App } from "./App";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import store from "./store";

// Required to use CIcon
(React as any).icons = icons;


const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {
    console.log("App has been updated and needs refresh");
    updateSW(true);
  },
  onOfflineReady() {
    console.log("App is now available offline");
  },
})

createRoot(document.getElementById("root")!).render(
  //<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
