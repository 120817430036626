import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import { tryRenewToken, startSocket } from "./session";

import "./scss/style.scss";
import { fromBase64URL } from "./utils";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

export const App = () => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    startSocket();

    const queryParam = new URLSearchParams(window.location.search).get("q");
    const authData = queryParam ? fromBase64URL(queryParam) : "";

    // https://base64.guru/standards/base64url/encode
    // Formato es "?u=<user>&p=<pass>" en base64url, replace everything between <>
    // dgarcia => P3U9ZGdhcmNpYUBpbmdlbml1bXNsLmNvbSZwPUluZ2VuMXVt
    // URL => https://devices.ingeniumsl.com/?q=P3U9ZGdhcmNpYUBpbmdlbml1bXNsLmNvbSZwPUluZ2VuMXVt
    const authParams = new URLSearchParams(authData);
    const user = authParams.get("u");
    const pass = authParams.get("p");
    if (user && pass) window.history.replaceState({}, document.title, window.location.origin);

    tryRenewToken(user, pass).then((success) => {
      const inLoginOrRegister =
        window.location.hash === "#/login" || window.location.hash === "#/register";

      if (!success && !inLoginOrRegister) {
        window.location.hash = "#/login";
      } else if (success && inLoginOrRegister) {
        window.location.hash = "#/";
      }

      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <div className="pt-5 mt-5 text-center">
        <div className="spinner-border spinner-border-lg" />
      </div>
    );
  }

  return (
    <React.Suspense fallback={loading}>
      <HashRouter>
        <Switch>
          <Route exact path="/login" render={(p) => <Login />} />
          <Route exact path="/register" render={(p) => <Register />} />
          <Route exact path="/404" render={(p) => <Page404 />} />
          <Route exact path="/500" render={(p) => <Page500 />} />
          <Route path="/" render={(p) => <TheLayout />} />
        </Switch>
      </HashRouter>
    </React.Suspense>
  );
};
