import { parseJSON, toBase64URL } from "./utils";

window.INGENIUM = {
  data: null,
  socket_started: false, // Make sure the socket is only started once
  socket_listeners: [], // Add any listener to the socket, format: {id: "", listener: m => {}}
};

export function getSessionToken(just_return = false) {
  const token = window.localStorage.getItem("TOKEN");
  if (!token) {
    if (just_return) {
      return null;
    }
    logOut();
    throw new Error("Session expired");
  }

  return token;
}

export function getSessionAdminToken() {
  const token = window.localStorage.getItem("ADMIN_TOKEN");
  if (!token) return null;

  return token;
}

export function setSessionToken(token: string | null, adminToken?: string | null) {
  if (token === null || token === undefined) {
    window.localStorage.removeItem("TOKEN");
  } else {
    window.localStorage.setItem("TOKEN", token);
  }

  if (adminToken === null) {
    window.localStorage.removeItem("ADMIN_TOKEN");
  } else if (adminToken) {
    window.localStorage.setItem("ADMIN_TOKEN", adminToken);
  }
}

export function getPanels() {
  const installation = window.INGENIUM?.data?.installations?.[0];
  const installation_data: InstallationData = parseJSON(installation?.data);
  return (
    installation_data?.panels?.filter((p) => {
      if (p.groups && installation?.groups) {
        const panel_groups = p.groups.split(" ");
        const user_groups = installation.groups.split(" ");

        // If the group limits are empty on the user or panel side, show it
        if (panel_groups.length === 0 || user_groups?.length === 0) {
          return true;
        }

        // Admins see it all
        if (user_groups?.indexOf("admin") !== -1) {
          return true;
        }

        for (let i in panel_groups) {
          const g = panel_groups[i];

          // If the user groups contain at least one of the panel groups show it
          if (user_groups.indexOf(g) !== -1) {
            return true;
          }
        }

        // Groups mismatch, don't show
        return false;
      }

      // If the panels are null, just allow it all
      return true;
    }) || []
  );
}

export function shouldShowDashboard() {
  const installation = window.INGENIUM?.data?.installations?.[0];
  const installation_data: InstallationData = parseJSON(installation?.data);

  const user_groups = installation?.groups?.split(" ");

  const has_panels_with_restrictions = installation_data?.panels?.some((p) => p?.groups?.trim());
  const user_is_admin = user_groups?.indexOf("admin") !== -1;

  return user_is_admin || !has_panels_with_restrictions;
}

export function isAdmin() {
  return window.INGENIUM?.data?.installations?.[0]?.groups?.split(" ").indexOf("admin") !== -1;
}

export function logOut() {
  setSessionToken(null, null);
  console.log("LOG OUT HASH " + window.location.hash);
  if (
    window.location.hash !== "#/login" &&
    //window.location.hash !== "#/" &&
    window.location.hash !== ""
  ) {
    //window.location.hash = "#/login";
    window.location.reload();

    console.log("LOG OUT RELOAD");
  }
}

export async function tryRenewToken(email: string | null, password: string | null) {
  try {
    const token = getSessionToken(true);
    if (!token && !(email && password)) {
      return false;
    }

    const r = await fetch("/api/renew", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ email, password }),
    });

    if (!r.ok) { return false; }

    const json = await r.json();
    if (!json || !json.token) {
      return false;
    }

    setSessionToken(json.token);
    delete json.token;
    window.INGENIUM.data = json;

    return true;
  } catch (e) {
    console.log(e);
    logOut();
    return false;
  }
}

export async function fetchReadings(
  devices: (Device | { id: string; type: number; origin: number })[],
  interval = 8 /* hours */,
  buckets?: number
): Promise<DeviceReading[] | null> {
  console.log("Fetching", interval, buckets);
  try {
    const token = getSessionToken();
    const r = await fetch("/api/readings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        devices: devices.map((d) => d.id),
        types: devices.map((d) => d.type),
        origins: devices.map((d) => d.origin),
        interval,
        buckets,
      }),
    });

    return await r.json();
  } catch (e) {
    console.error(e);
    //logOut();
    return null;
  }
}

export async function fetchUsers(installation: string): Promise<InstallationUser[] | null> {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ installation }),
    });

    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function fetchUsersAdmin(): Promise<InstallationUser[] | null> {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/users-admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function updateUser(
  installation: string,
  user: InstallationUser
): Promise<InstallationUser | { errorCode: number }> {
  const token = getSessionToken();
  const r = await fetch("/api/update-user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ ...user, installation }),
  });

  if (!r.ok) {
    return { errorCode: r.status };
  }

  return await r.json();
}

export async function detectDevices(installation: string): Promise<{ devices: Device[] } | null> {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/claim-network", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ installation }),
    });
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function registerExternalInstallation(
  installation: string,
  username: string,
  password: string
): Promise<ExternalInstallationResponse | { errorCode: number }> {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/claim-installation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ installation, username, password }),
    });
    if (!r.ok) {
      return { errorCode: r.status };
    }
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return { errorCode: 400 };
  }
}

export async function getExternalInstallation(
  id: string
): Promise<ExternalInstallationResponse | { errorCode: number }> {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/get-installation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id }),
    });
    if (!r.ok) {
      return { errorCode: r.status };
    }
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return { errorCode: 400 };
  }
}

export async function deleteExternalInstallation(id: string) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/delete-installation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id }),
    });
    if (!r.ok) {
      return { errorCode: r.status };
    }
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function editExternalInstallation(
  installation: string,
  id: string,
  devices: {
    address: number;
    type: number;
    output: number;
    label: string;
  }[]
) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/edit-installation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ installation, id, devices }),
    });
    if (!r.ok) {
      return { errorCode: r.status };
    }
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function saveDeviceNames(devices: { id: string; name: string }[]) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/save-devices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ devices }),
    });
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function deassociateDevice(id: string) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/deassociate-device", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id }),
    });
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function updateDevice(data: { id: string; name: string; applyAll: boolean } & object) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/device", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function saveInstallationData(id: string, data: string) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/installation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id, data }),
    });
    return await r.json();
  } catch (e) {
    console.error(e);
    logOut();
    return null;
  }
}

export async function tryLoginAs(email: string) {
  try {
    const token = getSessionToken();
    const r = await fetch("/api/loginas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ email }),
    });

    const json = await r.json();
    if (!json || !json.token) {
      return false;
    }

    setSessionToken(json.token, getSessionToken());
    delete json.token;
    window.INGENIUM.data = json;

    window.location.hash = "#/";
    window.location.reload();

    return true;
  } catch (e) {
    console.log(e);
    logOut();
    return false;
  }
}

export function returnToAdmin() {
  const admin_token = getSessionAdminToken();
  setSessionToken(admin_token, null);
  tryRenewToken(null, null).then((r) => {
    window.location.hash = "#/config/admin";
    window.location.reload();
  });
}

export async function tryLogin(email: string, password: string) {
  const loginUrl = "https://devices.ingeniumsl.com/?q=" + toBase64URL(`?u=${email}&p=${password}`);
  loginUrl.trim(); // Just to disable the unused warning
  console.log("Your login URL would be:", loginUrl);
  try {
    const r = await fetch("/api/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    });

    if (!r.ok) { return [false, r.status]; }

    const json = await r.json();
    if (!json || !json.token) {
      return [false, r.status];
    }

    setSessionToken(json.token);
    delete json.token;
    window.INGENIUM.data = json;

    window.location.hash = "#/";

    return [false, r.status];
  } catch (e) {
    console.log(e);
    logOut();
    return [false, 503];
  }
}

export async function tryRegister(email: string, name: string, password: string) {
  try {
    const r = await fetch("/api/register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, name, password }),
    });

    if (!r.ok) {
      return r.status;
    }

    const json = await r.json();
    if (!json || !json.token) {
      return 600;
    }

    setSessionToken(json.token);
    delete json.token;
    window.INGENIUM.data = json;

    window.location.hash = "#/";

    return 0;
  } catch (e) {
    console.log(e);
    logOut();
    return 700;
  }
}

export function startSocket() {
  if (window.INGENIUM.socket_started) {
    return;
  }
  window.INGENIUM.socket_started = true;

  let socket: WebSocket | null = null;
  const onerror = (e: Event | CloseEvent): any => {
    console.log("Socket closed, retrying...", e);
    socket = null;
  };

  const connectSocket = () => {
    if (socket != null) return;

    const token = getSessionToken(true);
    if (!token) return;

    const socket_proto = window.location.protocol.startsWith("https") ? "wss://" : "ws://";
    const socket_url = socket_proto + window.location.host + "/ws?auth=" + token;

    socket = new WebSocket(socket_url);
    window.INGENIUM.socket = socket;
    socket.onopen = (e) => console.log("Socket open", e);

    socket.onerror = onerror;
    socket.onclose = onerror;
    socket.onmessage = (e) => {
      try {
        const pkg = JSON.parse(e.data);
        console.debug("<< Received message:", JSON.stringify(pkg));

        for (let i in window.INGENIUM.socket_listeners) {
          const entry = window.INGENIUM.socket_listeners[i];
          if (entry && entry.listener) {
            entry.listener(pkg);
          }
        }
      } catch (e) {
        console.error("Error on socket message", e);
        console.error("Original message data was", (e as any)?.data);
      }
    };
  };

  setInterval(connectSocket, 10_000); // retry connection every 10 seconds
  setTimeout(connectSocket, 1_500); // Delay the start of the connection by 1.5 seconds
}

export function addSocketListener(id: string, listener: (p: DeviceReading) => void) {
  window.INGENIUM.socket_listeners.push({ id, listener });
}

export function removeSocketListener(id: string) {
  for (let i in window.INGENIUM.socket_listeners) {
    const entry = window.INGENIUM.socket_listeners[i];

    if (entry && entry.id === id) {
      window.INGENIUM.socket_listeners.splice(+i, 1);
      return;
    }
  }
}

export function socketSend(id: string, cmd: number, d1: number, d2: number) {
  const msg = { ident: id, cmd: cmd, d1: d1, d2: d2 };
  console.debug(">> Sending  message:", msg);
  window.INGENIUM.socket?.send(JSON.stringify(msg));
}

window.INGENIUM.socket_send = socketSend;
