import { RGBColor } from "react-color";
import { Buffer } from "buffer";
import React from "react";
import { CSSProperties } from "react";

export const PANEL_PREFIX = "/panel/";

export const ZERO_ID = "00000000-0000-0000-0000-000000000000";

export const ORIGIN_IOT = 0;
export const ORIGIN_BUSING = 1;
export const ORIGIN_KNX = 2;

export const TYPE_IOT_THERMOSTAT = 6;
export const TYPE_IOT_CO2 = 8;
export const TYPE_IOT_METERBUS = 46;

export const TYPE_BUSING_SIF = 34;
export const TYPE_BUSING_MULTIMETER = 86;
export const TYPE_BUSING_MASTERCLIMA = 49;
export const TYPE_BUSING_THERMOSTAT_LIST = [6, 29, 33, 44, TYPE_BUSING_MASTERCLIMA, 56, 61];
export const TYPE_BUSING_ACTUATOR_LIST = [4, 15, 24];
export const TYPE_BUSING_REGULATOR_LIST = [3, 12, 13];

export const OUTPUT_BUSING_ACTUATOR_CURRENT = 200;
export const OUTPUT_BUSING_ACTUATOR_VOLTAGE = 201;
export const OUTPUT_BUSING_ACTUATOR_POWER = 202;

export const TYPE_KNX_READING = 250;

export const OUTPUT_KNX_FLOAT = 1;
export const OUTPUT_KNX_UINT = 2;
export const OUTPUT_KNX_INT = 3;

export function parseJSON(json?: string) {
  if (!json) return null;
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}

export const isDeviceEmbedded = "android" in window;

export const embeddedConfigureWifiDevice = () => {
  try {
    (window as any).android.configureWifiDevice();
  } catch (e) {
    console.log("Error in embeddedConfigureWifiDevice", e);
  }
};

const toBase64 = function (unencoded: string) {
  return Buffer.from(unencoded).toString("base64");
};

const fromBase64 = function (encoded: string) {
  return Buffer.from(encoded, "base64").toString("utf8");
};

export const toBase64URL = function (unencoded: string) {
  const encoded = toBase64(unencoded);
  return encoded.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/g, "");
};

export const fromBase64URL = (encoded: string): string => {
  encoded = encoded.replace(/-/g, "+").replace(/_/g, "/");
  while (encoded.length % 4 !== 0) encoded += "=";
  return fromBase64(encoded);
};

export const isOffscreen = (el: Element) => {
  const { x, y, width, height } = el.getBoundingClientRect();
  return x + width < 0 || y + height < 0 || x > window.innerWidth || y > window.innerHeight;
};
export const minutesAgo = (from: number, to: number) => (from - to) / (1000 * 60);
export const hoursAgo = (from: number, to: number) => (from - to) / (1000 * 60 * 60);

export const rgbToHex = (r: number, g: number, b: number) =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const hexToRgb = (hex: string): RGBColor => {
  // The replace is to convert "#123" into "#112233"
  const arr = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16)) || [0, 0, 0];

  return { r: arr[0], g: arr[1], b: arr[2], a: 1 };
};

export const gradientBg = (hexColor: string) => {
  const { r, g, b } = hexToRgb(hexColor) || [55, 126, 240];
  const [r2, g2, b2] = [r - 15, g - 25, b - 25];
  return {
    background: `linear-gradient(45deg, rgb(${r}, ${g}, ${b}) 0%, rgb(${r2}, ${g2}, ${b2}) 100%)`,
    borderColor: `rgb(${r2}, ${g2}, ${b2})`,
  };
};

export const hasIcon = (name?: string): boolean => {
  if (!name) return false;

  const nameLower = name.replace(/-/g, "").toLowerCase();

  const iconNames = Object.keys((React as any)?.icons ?? {}) ?? [];
  const iconNamesLower = iconNames.map((e) => e.replace(/-/g, "").toLowerCase());
  const idx = iconNamesLower.indexOf(nameLower);
  return idx !== -1;
};

export const showHourFromUtc = (hour: number, minute: number) => {
  const { hour: h, minute: m } = weekdayHourUtcToLocal({ hour, minute });
  let hs = h.toString();
  let ms = m!.toString();
  if (hs.length === 1) hs = "0" + hs;
  if (ms.length === 1) ms = "0" + ms;
  return hs + ":" + ms;
};

export const useForceUpdate = (): (() => void) => {
  return React.useReducer(() => ({}), {})[1] as () => void;
};

export const dowloadCsvFile = (csv: string, name: string) => {
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", name);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const GetLetterIcon = ({
  letter,
  color = "var(--ci-primary-color, currentColor)",
  bgColor,
  className,
  fontFactor = 1,
  fontWeight = "bold",
  style,
  onClick,
}: {
  letter: string;
  color?: string;
  bgColor?: string;
  className?: string;
  fontFactor?: number;
  fontWeight?: string | number;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
      style={{ userSelect: "none", ...style }}
      onClick={onClick}
    >
      {bgColor && <circle cx="50%" cy="50%" r="50%" fill={bgColor} />}
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        dx="-.03em"
        dy=".1em"
        fill={color}
        fontWeight={fontWeight}
        fontSize={512 * fontFactor}
      >
        {letter[0].toUpperCase()}
      </text>
    </svg>
  );
};

export type WeekdayHour = {
  weekday?: number;
  hour: number;
  minute?: number;
};

export const weekdayHourLocalToUtc = (initial: WeekdayHour): WeekdayHour => {
  const d = { ...initial };

  // Offset from UTC, Spain is +1, note that we divide by -60 because getTimezoneOffset is defined that way
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#negative_values_and_positive_values
  const tzOffset = new Date().getTimezoneOffset() / -60;

  d.hour -= tzOffset;
  // If hour is under zero, we have to substract a day
  if (d.hour < 0) {
    d.hour += 24;
    // But only if we don't have enabled all days
    if (d.weekday != undefined && d.weekday != 7) {
      d.weekday = (d.weekday - 1 + 7) % 7;
    }
  }
  // If hour is over 23, we have to add a day
  else if (d.hour >= 23) {
    d.hour -= 24;
    // But only if we don't have enabled all days
    if (d.weekday != undefined && d.weekday != 7) {
      d.weekday = (d.weekday + 1) % 7;
    }
  }

  return d;
};

export const weekdayHourUtcToLocal = (initial: WeekdayHour): WeekdayHour => {
  const d = { ...initial };

  // Offset from UTC, Spain is +1, note that we divide by -60 because getTimezoneOffset is defined that way
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#negative_values_and_positive_values
  const tzOffset = new Date().getTimezoneOffset() / -60;

  d.hour += tzOffset;
  // If hour is under zero, we have to substract a day
  if (d.hour < 0) {
    d.hour += 24;
    // But only if we don't have enabled all days
    if (d.weekday != undefined && d.weekday != 7) {
      d.weekday = (d.weekday - 1 + 7) % 7;
    }
  }
  // If hour is over 23, we have to add a day
  else if (d.hour >= 23) {
    d.hour -= 24;
    // But only if we don't have enabled all days
    if (d.weekday != undefined && d.weekday != 7) {
      d.weekday = (d.weekday + 1) % 7;
    }
  }

  return d;
};

export type WeekdayBitsHour = {
  weekdays?: number;
  hour: number;
  minute?: number;
};

export const weekdayBitsHourLocalToUtc = (initial: WeekdayBitsHour): WeekdayBitsHour => {
  const d = { ...initial };

  // Offset from UTC, Spain is +1, note that we divide by -60 because getTimezoneOffset is defined that way
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#negative_values_and_positive_values
  const tzOffset = new Date().getTimezoneOffset() / -60;

  d.hour -= tzOffset;
  // If hour is under zero, we have to shift all days one day back
  if (d.hour < 0) {
    d.hour += 24;

    if (d.weekdays != undefined) {
      const monday = d.weekdays & 1;
      d.weekdays >>= 1;
      d.weekdays |= monday << 6;
    }
  }
  // If hour is over 23, we have to shift all days one day forward
  else if (d.hour >= 23) {
    d.hour -= 24;

    if (d.weekdays != undefined) {
      const sunday = d.weekdays & (1 << 6);
      d.weekdays <<= 1;
      d.weekdays |= sunday >> 6;
    }
  }

  return d;
};

export const weekdayBitsHourUtcToLocal = (initial: WeekdayBitsHour): WeekdayBitsHour => {
  const d = { ...initial };

  // Offset from UTC, Spain is +1, note that we divide by -60 because getTimezoneOffset is defined that way
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#negative_values_and_positive_values
  const tzOffset = new Date().getTimezoneOffset() / -60;

  d.hour += tzOffset;
  // If hour is under zero, we have to shift all days one day back
  if (d.hour < 0) {
    d.hour += 24;

    if (d.weekdays != undefined) {
      const monday = d.weekdays & 1;
      d.weekdays >>= 1;
      d.weekdays |= monday << 6;
    }
  }
  // If hour is over 23, we have to shift all days one day forward
  else if (d.hour >= 23) {
    d.hour -= 24;

    if (d.weekdays != undefined) {
      const sunday = d.weekdays & (1 << 6);
      d.weekdays <<= 1;
      d.weekdays |= sunday >> 6;
    }
  }

  return d;
};

(window as any).weekdayBitsHourLocalToUtc = weekdayBitsHourLocalToUtc;
(window as any).weekdayBitsHourUtcToLocal = weekdayBitsHourUtcToLocal;

export const isDeviceReading = (obj: any): obj is DeviceReading => {
  return obj.id !== undefined && obj.time !== undefined;
};

export const getDataValue = (
  device: Device,
  mode: number,
  reading: DeviceReading | DeviceInner
): number | undefined => {
  if (!reading) return undefined;
  let type = device?.type;

  if (isDeviceReading(reading)) {
    if (type === TYPE_IOT_CO2) {
      if (device.origin === ORIGIN_BUSING) {
        switch (mode) {
          case 0: return (reading.values?.[0] ?? 20_000) < 20_000 ? reading.values?.[0] : undefined;
          case 1: return (reading.values?.[1] ?? 20_000) < 20_000 ? reading.values?.[1] : undefined;
          case 2: return (reading.values?.[2] ?? 255) < 255 ? reading.values?.[2]! / 2.0 - 25 : undefined;
          case 3: return (reading.values?.[3] ?? 255) < 255 ? reading.values?.[3]! / 2.0 : undefined;
          default: return undefined;
        }
      } else {
        switch (mode) {
          case 0: return (reading.co2 ?? 20_000) < 20_000 ? reading.co2 : undefined;
          case 1: return (reading.vocs ?? 20_000) < 20_000 ? reading.vocs : undefined;
          case 2: return (reading.temp ?? 255) < 255 ? reading.temp! / 2.0 - 25 : undefined;
          case 3: return (reading.hum ?? 255) < 255 ? reading.hum! / 2.0 : undefined;
          default: return undefined;
        }
      }
    }

    if (type === TYPE_KNX_READING && device.origin === ORIGIN_KNX) {
      if (device.output === OUTPUT_KNX_FLOAT) return reading.values?.[0] !== undefined ? reading.values?.[0] / 100 : undefined;
      else if (device.output === OUTPUT_KNX_UINT) return reading.values?.[0] !== undefined ? reading.values?.[0] : undefined;
      else if (device.output === OUTPUT_KNX_INT) return reading.values?.[0] !== undefined ? reading.values?.[0] : undefined;
      // TODO: Change this
      else return undefined;
    }

    if (type === TYPE_IOT_METERBUS) {
      if (device.origin === ORIGIN_BUSING) {
        let value = [reading.values?.[0], reading.values?.[1], reading.values?.[2], reading.values?.[3]][mode];
        return (value ?? 65535) < 65535 ? value : undefined;
      } else {
        return (reading.values?.[mode] ?? 65535) < 65535 ? reading.values?.[mode] : undefined;
      }
    }

    if (type === TYPE_BUSING_SIF && device.origin === ORIGIN_BUSING) {
      switch (mode) {
        case 0: return (reading.values?.[0] ?? 65535) < 65535 ? reading.values?.[0]! / 100 : undefined;
        case 1: return (reading.values?.[1] ?? 65535) < 65535 ? reading.values?.[1] : undefined;
        case 2: return (reading.values?.[2] ?? 65535) < 65535 ? reading.values?.[2] : undefined;
        case 3: return (reading.values?.[3] ?? 65535) < 65535 ? reading.values?.[3]! / 100 : undefined;
        default: return undefined;
      }
    }

    if (type === TYPE_BUSING_MULTIMETER && device.origin === ORIGIN_BUSING) {
      const max = 2_147_483_647; // Max of signed 32 bit num
      switch (mode) {
        case 0: return (reading.values?.[0] ?? max) < max ? reading.values?.[0] : undefined;
        case 1: return (reading.values?.[1] ?? max) < max ? reading.values?.[1] : undefined;
        case 2: return (reading.values?.[2] ?? max) < max ? reading.values?.[2] : undefined;
        case 3: return (reading.values?.[3] ?? max) < max ? reading.values?.[3] : undefined;
        case 4: return (reading.values?.[4] ?? max) < max ? reading.values?.[4] : undefined;
      }
      return undefined;
    }

    if (TYPE_BUSING_THERMOSTAT_LIST.indexOf(type) !== -1 && device.origin === ORIGIN_BUSING) {
      if (type === TYPE_BUSING_MASTERCLIMA) {
        if (mode < 0 || mode > 32) return undefined;
        const zone_val = reading.values?.[Math.floor(mode / 4)];
        if (zone_val === undefined) return undefined;

        let reg = mode % 4;
        let value = (zone_val >> (reg * 8)) & 0xFF;

        switch (reg) {
          case 0: return value; // Estado
          case 1: return value; // Funcionamiento
          case 2: return value > 102 ? undefined : (value / 2.0); // Consigna
          case 3: return (value < 50 || value > 162) ? undefined : ((162.0 - value) / 2.0); // Medida
        }

      } else {
        return (reading.values?.[0] ?? 65535) < 65535 ? reading.values?.[0]! / 100 : undefined;
      }
    }
  }

  if (TYPE_BUSING_REGULATOR_LIST.indexOf(type) !== -1 && device.origin === ORIGIN_BUSING) {
    const val = (isDeviceReading(reading) ? reading.values?.[0] : reading.values?.[0]) ?? 0;
    if (mode == 0) {
      return (val & 0xff00) >> 8;
    } else if (mode == 1) {
      return val & 0x00ff;
    }
  }

  if (
    TYPE_BUSING_THERMOSTAT_LIST.indexOf(type) !== -1 &&
    (device.origin === ORIGIN_BUSING || device.origin === ORIGIN_IOT)
  ) {
    if (type === TYPE_BUSING_MASTERCLIMA) {
      if (mode < 0 || mode > 32 || mode === undefined) return undefined;
      const zone_val = reading.values?.[Math.floor(mode / 4)];
      if (zone_val === undefined) return undefined;

      let reg = mode % 4;
      let value = (zone_val >> (reg * 8)) & 0xFF;

      switch (reg) {
        case 0: return value; // Estado
        case 1: return value; // Funcionamiento
        case 2: return value > 102 ? undefined : (value / 2.0); // Consigna
        case 3: return (value < 50 || value > 162) ? undefined : ((162.0 - value) / 2.0); // Medida
      }
    } else {
      if (mode === 0 || mode === 1 || mode === 2) {
        const val =
          (isDeviceReading(reading) && device.origin === ORIGIN_BUSING
            ? [reading.values?.[0], reading.values?.[1], reading.values?.[2]][mode]
            : reading.values?.[mode]) ?? 65535;

        return val === 65535 ? undefined : val / 100;
      }

      if (mode === 3) {
        // 1-On, 0-Off
        return (reading.values?.[3] ?? 0) & 1;
      }

      if (mode === 4) {
        // 0-Off, 1-Calor, 2-Frio, 3-Auto
        return (reading.values?.[4] ?? 0) & 3;
      }
    }
  }

  if (TYPE_BUSING_ACTUATOR_LIST.indexOf(type) !== -1 && device.origin === ORIGIN_BUSING) {
    if (mode === OUTPUT_BUSING_ACTUATOR_CURRENT) {
      const val = (((isDeviceReading(reading) ? reading.values?.[1] : reading.values?.[1]) ?? 65535) & 0xff00) >> 8;
      return val === 255 ? undefined : val;
    }

    if (mode === OUTPUT_BUSING_ACTUATOR_VOLTAGE) {
      const val = ((isDeviceReading(reading) ? reading.values?.[1] : reading.values?.[1]) ?? 65535) & 0x00ff;
      return val === 255 ? undefined : val;
    }

    if (mode === OUTPUT_BUSING_ACTUATOR_POWER) {
      const val = (isDeviceReading(reading) ? reading.values?.[2] : reading.values?.[2]) ?? 65535;
      return val === 65535 ? undefined : val;
    }

    // Return switch on/off value
    const value = (isDeviceReading(reading) ? reading.values?.[0] : reading.values?.[0]) ?? 0;
    if (value !== 65535) {
      const pow = 1 << mode;
      return (value & pow) === pow ? 1 : 0;
    }
  }

  return undefined;
};

export const getDataValueFormat = (
  fmt: Intl.NumberFormat,
  device: Device,
  mode: number,
  reading: DeviceReading | DeviceInner
): string => {
  const val = getDataValue(device, mode, reading);

  if (val === undefined) return "";
  return fmt.format(val);
};

export const getDataUnit = (device: Device, mode: number, display?: number) => {
  let type = device?.type;
  if (type === TYPE_IOT_CO2) return [" PPM CO2", " PPM VOCs", " ºC", "% Hum."][mode];
  if (type === TYPE_KNX_READING && device.origin === ORIGIN_KNX) {
    const json = parseJSON(device.data);
    if (json && json.unit) return json.unit;
    return undefined;
  }
  if (type === TYPE_IOT_METERBUS) return " W";
  if (type === TYPE_BUSING_SIF) return [" ºC", " Detected", " lx", "% Hum."][mode];
  if (TYPE_BUSING_THERMOSTAT_LIST.indexOf(type) !== -1) return " ºC";

  if (TYPE_BUSING_REGULATOR_LIST.indexOf(type) !== -1 && device.origin === ORIGIN_BUSING) return "%";

  if (TYPE_BUSING_ACTUATOR_LIST.indexOf(type) !== -1 && device.origin === ORIGIN_BUSING) {
    if (mode === OUTPUT_BUSING_ACTUATOR_CURRENT) return " A";
    if (mode === OUTPUT_BUSING_ACTUATOR_VOLTAGE) return " V";
    if (mode === OUTPUT_BUSING_ACTUATOR_POWER) return " W";
    return "";
  }


  if (type === TYPE_BUSING_MULTIMETER && device.origin === ORIGIN_BUSING) {
    let unit: string | undefined = undefined;

    try {
      // Try to get the name from the components
      for (let m of JSON.parse(device?.data ?? "{}")?.modes ?? []) {
        if (m.output === mode && m.label) {
          unit = m.label;
          break;
        }
      }

      // Otherwise use the name from the device
      if (!unit) { unit = (device?.name ?? ""); }

      // Get the unit
      unit = unit.split(/\%[\.0-9]*[df]/)?.[1]?.trim();
      if (!unit) return undefined;

      // If getting the instant value, convert the units
      if (display == 1) {
        if (unit.toLowerCase() === "wh") return " W";
        if (unit.toLowerCase() === "kwh") return " kW";
        return " " + unit + "/h";
      } else {
        return " " + unit;
      }

    } catch (e) {
      console.error("getDataUnit name", e);
    }
  };

  return undefined;
};

export const getDeviceModeName = (dev: Device | undefined, mode: number) => {
  if (!dev) return "Undefined";

  try {
    const data_json = JSON.parse(dev?.data ?? "{}");
    for (let m of data_json?.modes ?? []) {

      let mode2 = mode;
      if (dev.type == TYPE_BUSING_MASTERCLIMA) {
        mode2 = Math.floor(mode / 4);
      }

      if (m.output === mode2 && m.label) {
        const new_name = (m.label ?? "").split(/\%[df]/)?.[0].trim();
        if (new_name) return new_name;
        break;
      }
    }
  } catch (e) {
    console.error("getDeviceModeName name", e);
  }

  return dev.name;
}

export const getDeviceType = (dev: Device | ExternalDevice | undefined) => {
  if (!dev) return "Invalid";
  let type = dev.type;

  if (type === TYPE_IOT_CO2) return "Sensor CO2";
  if (type === TYPE_KNX_READING && dev.origin === ORIGIN_KNX) return "Notificación KNX";
  if (type === 4) return "6E6S";
  if (type === 15) return "4E4S";
  if (type === 24) return "2E2S";
  if (TYPE_BUSING_REGULATOR_LIST.indexOf(type) !== -1) return "Regulador";
  if (type === TYPE_IOT_METERBUS) return "Meterbus";
  if (type === TYPE_BUSING_SIF) return "Multisensor";
  if (type === TYPE_BUSING_MULTIMETER) return "Multimeter";
  if (type === TYPE_BUSING_MASTERCLIMA) return "Masterclima";
  if (TYPE_BUSING_THERMOSTAT_LIST.indexOf(type) !== -1) return "Termostato";

  return "Unknown (" + type + ")";
};

export const pathify = (text: string) => {
  return text
    .toLowerCase()
    .replace(/ñ/g, "n")
    .replace(/[^/a-z0-9.-]/gi, "_")
    .replace(/\.{2,}/g, ".")
    .slice(0, 100);
};

export const moveArrayElem = (arr: any[], from: number, to: number) => {
  const elem = arr.splice(from, 1)[0];
  arr.splice(to, 0, elem);
};

export const toGroupAddress = (addr: number) => {
  const a = addr >> 11;
  const b = (addr >> 8) & 0x07;
  const c = addr & 0xff;
  return `${a}/${b}/${c}`;
};

export const parseGroupAddress = (addr: string) => {
  try {
    const split = addr.split("/");
    if (split.length === 0 || split.length > 3) return 65535;

    let addr_num = 0;

    if (split.length >= 1) {
      let num = parseInt(split[0], 10);
      if (num > 31 || isNaN(num)) return 65535;
      addr_num += (num & 0x1f) << 11;
    }

    if (split.length >= 2) {
      let num = parseInt(split[1], 10);
      if (num > 7 || isNaN(num)) return 65535;
      addr_num += (num & 0x07) << 8;
    }

    if (split.length >= 3) {
      let num = parseInt(split[2], 10);
      if (num > 255 || isNaN(num)) return 65535;
      addr_num += num;
    }

    return addr_num;
  } catch (e) {
    return 65535;
  }
};
